/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Pustak keramzytowy AKU K3 18 to kolejny krok w kierunku uzyskania wyższej efektywności. Celem firmy Czamaninek było stworzenie pustaka, który przy grubości zaledwie 18 cm, pozwoli na uzyskanie bardzo dobrej izolacyjność termicznej i akustycznej przegrody. Trzy rzędy komór umożliwiły uzyskanie współczynnika przewodzenia ciepła na poziomie 0.280 W/mK, dzięki czemu wymagania Rozporządzenia Ministra Infrastruktury spełnia przegroda ocieplona 15 cm wełny mineralnej lub styropianu, a stosując 20 cm wysokiej jakości izolacji termicznej osiągniemy standard domu pasywnego przy całkowitej grubości przegrody poniżej 40 cm."), "\n", React.createElement("table", {
    class: "table-section align-left"
  }, React.createElement("tr", null, React.createElement("th", {
    colspan: "2"
  }, "Bloczek AKU K3 18")), React.createElement("tr", null, React.createElement("td", null, "Wymiary"), React.createElement("td", null, "Długość: 375 mm", React.createElement("br"), " Szerokość: 180 mm", React.createElement("br"), " Wysokość: 240 mm", React.createElement("br"), "Kategoria odchyłek wymiarów: D1")), React.createElement("tr", null, React.createElement("td", null, "Masa"), React.createElement("td", null, "15 kg")), React.createElement("tr", null, React.createElement("td", null, "Klasa wytrzymałości"), React.createElement("td", null, "4.0 N/mm", React.createElement("sup", null, "2"))), React.createElement("tr", null, React.createElement("td", null, "Grupa elementu"), React.createElement("td", null, "1")), React.createElement("tr", null, React.createElement("td", null, "Współczynnik przewodzenia ciepła ", React.createElement("i", null, "I")), React.createElement("td", null, "0.280 ", React.createElement("i", null, "W/m*K"))), React.createElement("tr", null, React.createElement("td", null, "Opór cieplny ", React.createElement("i", null, "R")), React.createElement("td", null, "0.643 ", React.createElement("i", null, "m", React.createElement("sup", null, "2"), "/K*W"))), React.createElement("tr", null, React.createElement("td", null, "Współczynnik przenikania ciepła ", React.createElement("i", null, "U")), React.createElement("td", null, "1.230 ", React.createElement("i", null, "W/m", React.createElement("sup", null, "2"), "*K")))), "\n", React.createElement("table", {
    class: "table-section align-center table-layout-auto"
  }, React.createElement("tr", null, React.createElement("th", {
    rowspan: "2"
  }, "Rodzaj ściany nośnej"), React.createElement("th", {
    rowspan: "2"
  }, "Tynk c-w lekki Czamaninek"), React.createElement("th", {
    colspan: "6"
  }, "Izolacja termiczna - EPS (0.031)"), React.createElement("th", {
    rowspan: "2"
  }, "Tynk cienkowarstwowy")), React.createElement("tr", {
    class: "nowrap"
  }, React.createElement("td", null, "5 cm"), React.createElement("td", null, "10 cm"), React.createElement("td", null, "12 cm"), React.createElement("td", null, "15 cm"), React.createElement("td", null, "18 cm"), React.createElement("td", null, "20 cm")), React.createElement("tr", {
    class: "nowrap"
  }, React.createElement("td", null, "AKU K5 24 cm"), React.createElement("td", null, "15 mm"), React.createElement("td", null, "0,34"), React.createElement("td", null, "0,22"), React.createElement("td", {
    class: "green-bg"
  }, "0,19"), React.createElement("td", {
    class: "green-bg"
  }, "0,16"), React.createElement("td", {
    class: "green-bg"
  }, "0,14"), React.createElement("td", {
    class: "green-bg"
  }, "0,13"), React.createElement("td", null, "5 mm")), React.createElement("tr", {
    class: "nowrap"
  }, React.createElement("td", null, "AKU K3 18 cm"), React.createElement("td", null, "15 mm"), React.createElement("td", null, "0,41"), React.createElement("td", null, "0,25"), React.createElement("td", null, "0,21"), React.createElement("td", {
    class: "green-bg"
  }, "0,18"), React.createElement("td", {
    class: "green-bg"
  }, "0,15"), React.createElement("td", {
    class: "green-bg"
  }, "0,14"), React.createElement("td", null, "5 mm")), React.createElement("tr", {
    class: "nowrap"
  }, React.createElement("td", null, "AKU K4 EKO Plus 24 cm"), React.createElement("td", null, "15 mm"), React.createElement("td", null, "0,39"), React.createElement("td", null, "0,24"), React.createElement("td", null, "0,21"), React.createElement("td", {
    class: "green-bg"
  }, "0,17"), React.createElement("td", {
    class: "green-bg"
  }, "0,15"), React.createElement("td", {
    class: "green-bg"
  }, "0,14"), React.createElement("td", null, "5 mm")), React.createElement("tr", {
    class: "nowrap"
  }, React.createElement("td", null, "Bloczek AKU K2 18 cm"), React.createElement("td", null, "15 mm"), React.createElement("td", null, "0,43"), React.createElement("td", null, "0,25"), React.createElement("td", null, "0,22"), React.createElement("td", {
    class: "green-bg"
  }, "0,18"), React.createElement("td", {
    class: "green-bg"
  }, "0,15"), React.createElement("td", {
    class: "green-bg"
  }, "0,14"), React.createElement("td", null, "5 mm")), React.createElement("tr", {
    class: "nowrap"
  }, React.createElement("td", null, "Bloczek akustyczny 18 cm"), React.createElement("td", null, "15 mm"), React.createElement("td", null, "0,49"), React.createElement("td", null, "0,27"), React.createElement("td", null, "0,23"), React.createElement("td", {
    class: "green-bg"
  }, "0,19"), React.createElement("td", {
    class: "green-bg"
  }, "0,16"), React.createElement("td", {
    class: "green-bg"
  }, "0,15"), React.createElement("td", null, "5 mm"))), "\n", React.createElement(_components.p, null, "Mniejsza grubość ściany nie oznacza przy tym, że pustak keramzytowy AKU K3 18 charakteryzuje się niższą wytrzymałością na ściskanie. Dzięki odpowiedniemu dobraniu receptury udało się utrzymać ten parametr na poziomie 4.0 N/mm", React.createElement("sup", null, "2"), ". Również izolacyjność akustyczna jest na wysokim poziomie – badania w Instytucie Techniki Budowlanej (ITB) potwierdziły deklarowane 51 dB. Pustak AKU K3 18 polecamy szczególnie deweloperom, gwarantuje on uzyskanie większej powierzchni użytkowej przy utrzymaniu świetnych parametrów termicznych i akustycznych."), "\n", React.createElement(_components.p, null, "Pomimo mniejszej grubości, przegrody wykonane z pustaków AKU K3 18 charakteryzują się odpornością ogniową REI240, dlatego można je z powodzeniem stosować praktycznie w każdego typu budynkach. Uzupełnieniem systemu są nadproża z betonu lekkiego o grubości 17.5 cm i wysokości konstrukcyjnej 11.5 cm. Ich stosowanie niweluje mostki termiczne liniowe i poprawia komfort cieplny w domu lub mieszkaniu. Do konstruowania ścian fundamentowych dla systemu z pustakami AKU K3 18 zalecamy stosowanie Bloczka akustycznego 18 o wytrzymałości na ściskanie 14 N/mm", React.createElement("sup", null, "2"), ", umożliwiającego również murowanie na pióro i wpust przy użyciu dedykowanego kleju firmy Czamaninek. W ten sposób uzyskujemy spójny system konstrukcyjny na bazie keramzytu. "));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
